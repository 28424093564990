export default [
  {
    title: 'กระเป๋าเงินของฉัน',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'เติมเครดิต',
        route: 'wallet-deposit',
      },
      {
        title: 'ถอนเงิน',
        route: 'wallet-withdraw'
      },
      {
        title: 'ประวัติยอดเงิน',
        route: 'wallet-history'
      },
    ],
  },
  {
    title: 'ประวัติของฉัน',
    icon: 'UserIcon',
    children: [
      {
        title: 'แก้ไขโปรไฟล์',
        route: 'profile-edit',
      },
      {
        title: 'เปลี่ยนรหัสผ่าน',
        route: 'profile-changepass'
      },
    ],
  },
]
