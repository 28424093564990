<template>
  <div>
    <b-modal
      v-model="$store.state.auth.modalCredit"
      hide-footer
      hide-header-close
      header-bg-variant="dark"
      body-bg-variant="black"
      size="md"
    >
      <!-- <template #modal-title>
        <span class="text-white">เติมเครดิต</span>
      </template> -->
      <b-tabs  v-model="$store.state.auth.indexModal" content-class="mt-3" lazy justified>
      <b-tab title="เติมเครดิต">
        <div class="mt-2 mb-2 text-center" v-if="dataBank.length > 0">
          <div class="col-12 text-center mb-2">
            <b-img width="100px" :src="require('@/assets/images/production/bank/' + dataBank[indexAccount].bankAccountName + '.jpeg')" rounded="circle" />
          </div>
          <div class="col-8 m-auto text-center">
            <label class="text-white">ชื่อบัญชี</label>
            <b-form-select class="text-center" v-model="selectedBank" :options="optionBank" />
          </div>
          <div class="col-8 m-auto text-center">
            <label class="text-white mt-1">ธนาคาร</label>
            <b-form-input class="text-center" v-model="dataBank[indexAccount].bankAccountName" readonly />
          </div>
          <div class="col-8 m-auto text-center">
            <label class="text-white mt-1">เลขบัญชี</label>
            <b-form-input class="text-center" ref="bankaccountNumber" v-on:focus="$event.target.select()" v-model="dataBank[indexAccount].bankAccountNumber" readonly />
          </div>
          <b-button variant="info" class="mt-1" @click="copy"><feather-icon icon="CopyIcon" /> Copy</b-button>
        </div>
      </b-tab>
       <b-tab title="ถอนเครดิต">
        <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body shadow rounded">
              <div class="row">
                <div class="col-xl-3 text-center">
                  <b-img
                    v-if="bankaccountName !== null"
                    :src="
                      require('@/assets/images/production/bank/' +
                        bankaccountName +
                        '.jpeg')
                    "
                    style="width: 100px"
                    @error="setAltImg"
                  />
                </div>
                <div class="col-xl-9 mt-1">
                  <div class="row mb-1">
                    <div class="col-xl-5 d-flex align-self-center">
                      <label style="font-size: 14px">ชื่อบัญชีธนาคาร</label>
                    </div>
                    <div class="col-xl-7 d-flex align-self-center">
                      <b-form-input v-model="fullName" readonly></b-form-input>
                    </div>
                  </div>
                  <!-- <div class="row mb-1">
                        <div class="col-xl-4 d-flex align-self-center">
                          <label style="font-size: 14px">ธนาคาร</label>
                        </div>
                        <div class="col-xl-8 d-flex align-self-center">
                          <b-form-input disabled></b-form-input>
                        </div>
                      </div> -->
                  <div class="row mb-1">
                    <div class="col-xl-5 d-flex align-self-center">
                      <label style="font-size: 14px">เลขบัญชีธนาคาร</label>
                    </div>
                    <div class="col-xl-7 d-flex align-self-center">
                      <b-form-input
                        v-model="bankaccountNumber"
                        readonly
                      ></b-form-input>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
          <!-- end card -->
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-xl-12">
          <label style="font-size: 15px"
            ><font-awesome-icon :icon="['fas', 'coins']" style="width: 18px" />
            จำนวนเงินที่ถอนได้</label
          >
          <br />
          <div class="alert alert-success py-1">
            <h3 class="text-success text-center my-0 w-100 text-credit-balance">
              {{ creditBalance | toCurrencyBath }}
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <label style="font-size: 15px"
            ><font-awesome-icon
              :icon="['fas', 'hand-holding-usd']"
              style="width: 18px"
            />
            จำนวนเงินที่ต้องการถอน</label
          >
          <br />
          <b-input-group size="lg">
            <template #prepend>
              <b-input-group-text style="background-color: #e9ecef"
                ><span>฿</span></b-input-group-text
              >
            </template>
            <template #append>
              <b-button variant="warning" @click="buttonAll">ทั้งหมด</b-button>
            </template>
            <b-form-input v-model="credit" type="tel"></b-form-input>
          </b-input-group>
          <small class="text-secondary"
            >ถอนเงินขั้นต่ำ 100 หรือ สูงสุด 200,000 บาท</small
          >
        </div>
        <div class="col-xl-12">
          <label style="font-size: 15px" class="mt-1">
            <font-awesome-icon
              :icon="['far', 'star']"
              style="width: 18px"
            />หมายเหตุ</label
          >
          <b-form-textarea
            id="textarea"
            v-model="detail"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-xl-6 mb-1">
          <b-button variant="danger" class="w-100"
            >ยกเลิก</b-button
          >
        </div>
        <div class="col-xl-6 mb-1">
          <b-button variant="success" class="w-100" @click="onSubmit"
            >ยืนยัน</b-button
          >
        </div>
      </div>
      </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormInput, BFormSelect, BButton, BImg, BTabs, BTab, BInputGroup, BInputGroupText, BFormTextarea } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import PlaceHolder from "@/assets/images/production/place_images.png";


export default {
  components: {
    BModal,
    BFormInput,
    BFormSelect,
    BButton,
    BImg,
    BTabs,
    BTab,
    BInputGroup,
    BInputGroupText,
    BFormTextarea,
    FeatherIcon,
  },
  data() {
    return {
      selectedBank: '',
      indexAccount: 0,
      bankaccountName: null,
      fullName: "",
      detail: "",
      creditBalance: 0,
      bankaccountNumber: "",
      credit: 100,
    };
  },
  watch: {
    selectedBank(e) {
      this.indexAccount = this.dataBank.findIndex(x => x._id === e)
    },
  },
  computed: {
    dataBank() {
      return this.$store.state.bank.listBank
    },
    optionBank() {
      return this.$store.state.bank.listBank.map(x => ({ value: x._id, text: x.name }))
    },
  },
  created() {
      // this.selectedBank = this.$store.state.bank.listBank[0]._id
  },
  beforeUpdate() {
      this.selectedBank = this.dataBank[0]._id;
      this.loadData();
  },
  methods: {
    setAltImg(e) {
      console.log("alt");
      e.target.src = PlaceHolder;
    },
    show() {
      this.openModal = true;
    },
    close() {
      this.openModal = false;
    },
    copy() {
      this.$refs.bankaccountNumber.focus();
      document.execCommand('copy');
      alert("คัดลอกเลขบัญชีเรียบร้อยแล้ว!!")
    },
    // withdraw
    onSubmit() {
      if (this.credit >= 100 && this.credit <= this.creditBalance) {
        const payload = {
          creditAmount: this.credit,
          detail: this.detail,
        };
        this.$store
          .dispatch("transaction/Withdraw", payload)
          .then((result) => {
            if (result.data.success) {
              console.log(result);
              this.$router.replace("/history/withdraw").then(() => {
                const color =
                  result.data.message === "กรุณารอเจ้าหน้าที่ดำเนินการสักครู่"
                    ? "warning"
                    : "success";
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "ธุรกรรม",
                    icon: "DollarSignIcon",
                    variant: color,
                    text: result.data.message,
                  },
                });
              });
              this.$store.commit("auth/SET_MODAL_WITHDRAW", true);
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "ธุรกรรม",
                  icon: "DollarSignIcon",
                  variant: "danger",
                  text: result.data.message,
                },
              });
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "ธุรกรรม",
                icon: "DollarSignIcon",
                variant: "danger",
                text: error.message,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "ธุรกรรม",
            icon: "DollarSignIcon",
            variant: "danger",
            text: "กรุณากรอกจำนวนเงินมากกว่า 100 บาท",
          },
        });
      }
    },
    async loadData() {
      const token =
        localStorage.getItem("auth_token") !== null
          ? localStorage.getItem("auth_token")
          : null;
      if (token !== null) {
        this.$store
          .dispatch("transaction/getCredit")
          .then((result) => {
            if (result.data.success) {
              this.fullName =
                result.data.item.firstName + " " + result.data.item.lastName;
              this.bankaccountNumber = result.data.item.bankaccountNumber;
              this.bankaccountName = result.data.item.bankaccountName;
              this.$store.commit("auth/SET_CREDIT", result.data.credit);
              this.creditBalance = result.data.credit;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    buttonAll() {
      console.log("test");
      if (this.creditBalance <= 100) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "ธุรกรรม",
            icon: "DollarSignIcon",
            variant: "danger",
            text: "กรุณากรอกจำนวนเงินมากกว่า 100 บาท",
          },
        });
      } else {
        this.credit = this.creditBalance;
      }
    },
  }
};
</script>
