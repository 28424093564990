<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="mr-1 d-flex align-self-center">
        <p class="mb-0 text-right" style="font-size: 15px"><b-spinner v-if="loadCredit" small varaint="primary" /> {{ $store.state.auth.credit | toCurrency }} THB</p>
        <!-- <p class="text-success mb-0 text-right" style="font-size: 11px">
          0.00 THB
        </p> -->
      </div>
      <div class="user-nav d-xl-inline d-none">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>
    <b-dropdown-item @click="() => { $router.push('/profile') }"> โปรไฟล์ </b-dropdown-item>
    <b-dropdown-item @click="() => { $router.push('/deposit') }">
      เติมเงินเครดิต
    </b-dropdown-item>
    <b-dropdown-item @click="() => { $router.push('/withdraw') }"> ถอนเงิน </b-dropdown-item>
    <b-dropdown-item @click="() => { $router.push('/changepass') }"> เปลี่ยนรหัสผ่าน </b-dropdown-item>
    <b-dropdown-item href="#" @click="logout"> ออกจากระบบ </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSpinner,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BSpinner,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      loadCredit: true,
    };
  },
  created() {
    this.loadData()
  },
  methods: {
    logout() {
      console.log("test");
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_user");
      localStorage.removeItem("userData");
      this.$store.commit("auth/SET_LOGOUT");
      this.$store.commit("game/SET_LOADING", false)

      // Reset ability
      // this.$ability.update(initialAbility);

      // Redirect to login page
      if (this.$router.currentRoute.path !== "/")
      this.$router.push({ path: "/" });
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "AlertCircleIcon",
          variant: "success",
          text: "คุณออกจากระบบสำเร็จ",
        },
      });
      this.$store.commit('auth/SET_CREDIT', 0)
    },
    async onClickModalCredit(val) {
      await this.$store.dispatch('auth/getBank')
    },
    loadData() {
       this.loadCredit = true
       this.$store.dispatch('transaction/getCredit').then(result => {
          if (result.data.success) {
            this.$store.commit('auth/SET_CREDIT', result.data.credit)
            this.loadCredit = false
          } 
        }).catch(error => {
            console.log(error)
        })
    },
  },
};
</script>
